import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import createDecorator from "final-form-focus";
import { useStore } from "@/lib/storeData/StoreContext";
import {
  LoginFormProps,
  LoginFormValues,
  LoginMethod,
  TemplateElement,
} from "../templates/types";
import type { RendererComponent } from "./types";
import {
  LoginQuery,
  LoginQueryVariables,
  LoginDocument,
  MeQuery,
  MeQueryVariables,
  MeDocument,
} from "../generated/graphql";
import { registerUser } from "../lib/Authentication/utils";
import { useCartData } from "../lib/cartData/useCartData";
import { parseCookies } from "nookies";
import { useRouter } from "@/i18n/i18n-navigation";

const focusOnError = createDecorator<LoginFormValues>();

interface LoginFormRendererProps extends RendererComponent<LoginFormProps> {}

const LoginFormRenderer: TemplateElement<LoginFormRendererProps> = ({
  Component,
}) => {
  const router = useRouter();
  const { id: storeId } = useStore();
  const { query } = useApolloClient();
  const [activeTab, setActiveTab] = useState(LoginMethod.EMAIL);
  const [isError, setIsError] = useState(false);
  const {
    cart,
    updateContactInfo: { updateContactInfo },
  } = useCartData();

  const handleSubmitForm = async (values: LoginFormValues) => {
    const email = values?.email;
    const phone = values?.phone?.value;
    const phoneOrEmail = activeTab === LoginMethod.EMAIL ? email : phone!;
    try {
      const {
        data: { customerLoginV2: loginToken },
      } = await query<LoginQuery, LoginQueryVariables>({
        query: LoginDocument,
        variables: {
          storeId,
          password: values.password,
          phoneOrEmail,
        },
      });
      const { data: meData } = await query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        context: { headers: { authorization: `Bearer ${loginToken}` } },
      });
      registerUser("login", meData?.me!, loginToken!);

      const { sessionId } = parseCookies();
      if (
        sessionId &&
        ((values?.phone?.value &&
          values?.phone?.value !== cart?.contactInfo?.phone) ||
          (values?.email && values?.email !== cart?.contactInfo?.email))
      )
        await updateContactInfo({
          info: {
            name: meData?.me?.name!,
            email: meData?.me?.email,
            phone: meData?.me?.phone,
          },
        });

      router.replace("/");
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <Component
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      onSubmit={handleSubmitForm}
      isError={isError}
      setIsError={setIsError}
      focusOnError={focusOnError}
    />
  );
};

export default LoginFormRenderer;
