"use client";
import DefaultLogin from "@/templates/default/pages/Login";
import { LoginPageProps } from "@/templates/types";
import { AuthPagesLoader } from "../page-template-loaders/auth-pages-loader";

const LoginPage: React.FC<LoginPageProps> = (props) => {
  return (
    <AuthPagesLoader>
      <DefaultLogin {...props} />
    </AuthPagesLoader>
  );
};

export default LoginPage;
